<template>
    <div id="infos-page" class="pt-3 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack">
                <i class="fa fa-chevron-left"></i> retour
            </b-button>
            Notifications
        </div>
        <div class="row mt-5">
            <!--messages lues & non lues-->
            <div class="col-12 col-lg-4" v-if="selectedInfo === null">
                <!--messages non lues-->
                <div class="mb-3">
                    <h2 class="text-center text-white">Non-lues ({{ unreadNotifsCount }})</h2>
                    <div class="list-group text-dark">
                        <div v-for="info in unreadNotifs" :key="info.id">
                            <a
                                href="#infos"
                                @click="displayInfos($event, info);"
                                class="list-group-item list-group-item-action"
                            >
                                <div class="centered-ico col-2 bg-blue-news-info">
                                    <i class="fas fa-info-circle fa-2x text-white"></i>
                                </div>
                                <div class="align-items-center row text-dark">
                                    <div class="col-8 offset-2">
                                        <span class="d-block text-truncate font-weight-bold">{{
                                                info.payload.title
                                            }}</span>
                                        <span class="d-block text-truncate text-muted">Publiée le {{
                                                formatDate(info.created_at)
                                            }}</span>
                                    </div>
                                    <div class="col-2">
                                        <i class="fas fa-chevron-right fa-2x"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <!--messages lues-->
                <div class="mb-3">
                    <h2 class="text-center text-white">Lues ({{ readNotifsCount }})</h2>
                    <div class="list-group text-dark" style="opacity: 0.7">
                        <div v-for="info in readNotifs" :key="info.id">
                            <a
                                href="#infos"
                                @click="displayInfos($event, info)"
                                class="list-group-item list-group-item-action"
                            >
                                <div class="centered-ico col-2 bg-blue-news-info">
                                    <i class="fas fa-info-circle fa-2x text-white"></i>
                                </div>
                                <div class="align-items-center row text-dark">
                                    <div class="col-8 offset-2">
                                        <span class="d-block text-truncate font-weight-bold">{{
                                                info.payload.title
                                            }}</span>
                                        <span class="d-block text-truncate text-muted">Publiée le {{
                                                formatDate(info.created_at)
                                            }}</span>
                                    </div>
                                    <div class="col-2">
                                        <i class="fas fa-chevron-right fa-2x"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!--Visualiseur message-->
            <div class="col-12 message-visualizer d-lg-block" v-else>
                <h2 class="text-center text-white invisible">Message</h2>
                <div class="card">
                    <div class="card-header bg-info-corporate">
                        <div class="row">
                            <div class="col-12">
                                <span class="d-block text-truncate font-weight-bold">{{
                                        selectedInfo.payload.title
                                    }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-blue-news-date-title">
                                <span class="">Publiée le {{ formatDate(selectedInfo.created_at) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            {{ selectedInfo.payload.text }}
                        </div>
                        <div class="w-100" v-if="!selectedInfo.read">
                            <div class="btn btn-primary mt-2 d-flex text-center justify-content-center"
                                 v-for="(action, index) in selectedInfo.payload.actions" :key="index"
                                 @click="act(action, selectedInfo)"
                            >
                                <div>
                                    {{ action.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router'
import {mapGetters} from "vuex";
import actions from "@/actions";
import userService from "@/services/userService";

export default {
    name: "Infos",
    data() {
        return {
            infos: [],
            selectedInfo: null
        }
    },
    computed: {
        ...mapGetters({
            unreadNotifsCount: 'unreadNotifsCount',
            readNotifsCount: 'readNotifsCount',
            unreadNotifs: 'unreadNotifs',
            readNotifs: 'readNotifs'
        })
    },
    methods: {
        getBack() {
            if (this.selectedInfo) {
                this.selectedInfo = null
            } else {
                router.go(-1)
            }
        },
        displayInfos(event, info) {
            event.preventDefault()
            this.selectedInfo = info
            this.readNotif(info);
        },
        formatDate(date) {
            return this.$moment(date).format('DD MMMM YYYY')
        },
        act(action, notif) {
            const a = actions[action.action];
            if (a) {
                a.handler(notif.id, action.data);
                notif.read = true;
                this.selectedInfo = null;
            }
        },
        readNotif(info) {
            if(info.read) return;
            userService.readNotif(info.id);
            info.read = 1;
        }
    },
    mounted() {
        this.$emit('menu', 'infos')
    }
}
</script>

<style scoped>
#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus {
    box-shadow: none;
}
</style>
